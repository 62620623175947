.divchart {
    position: absolute;
    background-color: white;
    z-index: 96;
   /*  height: 76%; */
   height: 100%;
    width: 100%;
    padding: 20px;
    left: 100%;
    transition: 3s;
    overflow-y: scroll;
    padding-bottom: 3%;
    display: none;
    z-index: 95;
    padding-bottom: 10%;
}

.divchart.active {
    left: 0;
    display: block;
}

.divchart h1 {
    margin-top: 10px;
    font-family: 'Sarabun', sans-serif;
    text-align: center;
}
.pagination-section {
    margin: 3em 0;
    display: grid;
    place-items: center;
    height:20px;
    border-radius: 15%;
  }

  .pagination {
    list-style-type: none;
    display: flex;
    border: 1px solid black;
  }
  .pagination a {
    text-decoration: none;
    background: teal;
    color: #fff;
    padding: 0.5rem 0.5rem;
    height: 100%;
    border: 1px solid teal;
  }
  .pagination a:hover {
    background: rgb(204, 199, 199);
    color: #fff;
  }
  .pagination input {
   width: 50px;
   text-decoration: none;
   background: teal;
   color: #fff;
   padding: 0.5rem 0.5rem;
   border: 1px solid teal;
   border-radius: 0%;
   height: 100%;
  }
  .pagination p {
   text-decoration: none;
   background: teal;
   color: #fff;
   height: 100%;
   padding: 0.55rem 0.5rem;
   border: 1px solid teal;
  }


  .first a {
    display: inline-block;
    transition: all 300ms linear;
  }
  @keyframes move {
    0% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(-100px);
    }
    40% {
      transform: translateX(100px);
    }
    60% {
      transform: translateX(-50px);
    }
    80% {
      transform: translateX(50px);
    }
    100% {
      transform: translateX(0);
    }
  }
