@import url(https://fonts.googleapis.com/css?family=Kanit:300&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Kanit', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
html, body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }
/* .map {
    position: relative;
    height: 70vh;
    width: 100%;
    margin: 0;
    padding: 0;
} */
  .fullscreen:-webkit-full-screen {
    height: 100%;
    margin: 0;
  }
  .fullscreen:-ms-fullscreen {
    height: 100%;
  }

  .fullscreen:fullscreen {
    height: 100%;
  }


#panel {
    background-color: #A6BDCE;
    top: 0;
}


.filter {
    position: absolute;
    z-index: 998;
    margin: 4% 1%;
    border-style: outset;
    border-width: 5px;
    border-color: #21d4c3;
}

.description {
    position: absolute;
    z-index: 98;
    right: 0.5rem;
    top: 95%;
}

.showdescription {
    position: absolute;
    z-index: 99;
    right: 5rem;
    bottom: 2vh;
    display: none;
    max-height: 100vh;
    max-width: 450px;
    border: 2px solid #7EA6B9;
    background-color: #7ea6b947;
}

.showdescription img {
    width: 100%;
    height: 15vh;
}

#toolbal button {
    height: 50px;
    overflow: hidden;
    width: 145px;
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0px 0px;
    transition: 0.3s;
}
/*
#toolbal .dropdown-content button {
    background-color: #8dacbbb8;
}

#toolbal button:hover {
    background-color: #7EA6B9;
}

#toolbal button.active {
    background-color: #7EA6B9;
}
 */
#menu-top{
    position: -webkit-sticky;
    position: sticky;
    left: 50%;
}
#menu-top button {
    position: relative;
    background-color: #558ED5;
    border: none;
    height: 40px;
    margin-top: -6px;

    /* margin: 1px; */
}

#menu-top img {
    position: relative;
    border: none;
    top: 0px;
}

#menu-top button:hover {
    background-color: #f9fff9;
}

#menu-top .active {
    background-color: #f9fff9;
    display: inline-table;
}

.maptabnew {
    cursor: pointer;
    margin-left: 10px;
}

.maptabnew:hover {
    background-color: #6495ed;
}


.bootstrap-select .btn {
    background-color: #FFF;
    border-style: solid;
    border-left: none;
    border-top: none;
    border-bottom: none;
    border-right: none;
    color: gray;
    font-weight: 200;
    padding: 12px 12px;
    font-size: 20px;
    margin-bottom: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    overflow: hidden;
    text-align: center;
}


/* ---------------------------------------------------
  SIDEBAR STYLE
----------------------------------------------------- */

.sidebar-right {
    width: 100%;
    position: absolute;
    top: 0;
    right: -100%;
    height: 100%;
    z-index: 99;
    background: #FFF;
    color: #fff;
    transition: all 0.3s;
    overflow-y: scroll;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

.sidebar-right.active {
    right: 0;
}

#dismiss {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #7386D5;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    transition: all 0.3s;
}

#dismiss:hover {
    background: #fff;
    color: #7386D5;
}


/*------------------------------------เมนูslide-chart---------------------------------------*/

.menuchart {
    background-color: #21d4c3;
}

.menuchart:active {
    background-color: red;
}


/*---------------------------------------------ฟังชั่นเล่นแสดงmap range input play------------------------------------*/

.btnplayslidemap:hover {
    text-shadow: -2px 4px 0 #3cdaf3, -2px -4px 0 #3cdaf3, 4px 0px 0 #3cdaf3;
    cursor: pointer;
}

.range input {
    width: 100%;
    position: absolute;
    top: 50%;
    margin-left: 15px;
    height: 0;
    -webkit-appearance: none;
}

.range input::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    margin: -8px 0 0;
    border-radius: 50%;
    background: #37adbf;
    cursor: pointer;
    border: 0 !important;
}

.range input::-moz-range-thumb {
    width: 18px;
    height: 18px;
    margin: -8px 0 0;
    border-radius: 50%;
    background: #37adbf;
    cursor: pointer;
    border: 0 !important;
}

.range input::-ms-thumb {
    width: 18px;
    height: 18px;
    margin: -8px 0 0;
    border-radius: 50%;
    background: #37adbf;
    cursor: pointer;
    border: 0 !important;
}

.range input::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #b2b2b2;
}

.range input::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #b2b2b2;
}

.range input::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #b2b2b2;
}

.range input:focus {
    background: none;
    outline: none;
}

.range input::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}


/*---------------------------------------------------------------------------*/

.fs {
    font-size: 13px;
}


/*#navbar {
    background-image: url(http://gis.nso.go.th/portal/wp-content/uploads/2020/02/header-bgNew.png);
    background-size: 100%;
}*/


#navbar a {
   /*  color: white; */
}

.nav-link {
    transition: 0.3s linear;
}

.nav-link:hover {
    border-bottom: 2px solid #18A2B8;
    padding: 6px 0;
}

.nav-link.active {
    border-bottom: 2px solid #18A2B8;
    padding: 6px 0;
}


li {
    list-style-type: none;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #ffffff;
    content: "/";
}

.grecaptcha-badge {
    visibility: hidden;
    z-index: 2000;
}
.showwmsapi{
    overflow: auto;
    border: 1px solid black;
    height: 100px;

}
.showwmsapi::-webkit-scrollbar {
    width: 5px;
  }
.dropdown-menushow{
    display: flex;
}

@media only screen and (max-width: 800px) {
    #menu-top button {
        margin-top: 10px;
    }

}
@media only screen and (max-width: 600px) {
    .navlogo{
        width: 140px!important;
    }
    .footer{
        display: none;
    }
}
.sidenav {
  position: absolute;
  z-index: 97;
  height: 100%;
  width: 25%;
  left: -25%;
  background-color: #fff;
  overflow-x: none;
  transition: 0.5s all linear;
  border-top: 2px solid #23898e;
  border-bottom: 2px solid #23898e;
  border-right: 2px solid #23898e;

}
/*-----------------ลบปุ่มออกเมื่อเมนูปิดอยู่ */
.sidenav .sc-AxhUy{
 visibility: hidden;
 transition: 1s all linear ;
 z-index: 90;
}
.sidenav.active .sc-AxhUy{
  visibility: visible;
}
/*----------------------------------*/

.sidenavfullscreen {
  height: 100% !important;
}

.sidenav #open {
  visibility: visible;
}

.sidenav.active {
  position: absolute;
  z-index: 97;
  overflow-x: none;
  left: 0px !important;
}


.sidenav:hover i {
  margin-right: 10px;
  transform: rotate(180deg);
}

.tab {
  height: auto;
  width: 100%;
}

.tab .hide {
  display: none;
}

.tab button {
  display: inline;
}

.headnav button:hover {
  color: #d4cfcf;
}

.main {
  margin-left: 160px;
  /* Same as the width of the sidenav */
  font-size: 28px;
  /* Increased text to enable scrolling */
  padding: 0px 10px;
}

.hide {
  display: none;
}

.sidenav .sidecontent ,.sidecontentswipe{
  position: relative;
  width: 100%;
  height: 90%;

  padding-bottom: 30px;
  overflow-y: auto;


}

#dragbar {
  background-color: #67a6fc;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  cursor: col-resize;
  z-index: 99;
}

#dragbar:hover {
  width: 5px;
  cursor: col-resize;
  z-index: 99;
}
.description {
  position: relative;
}
@media only screen and (max-width: 900px) {
  .sidenav.active {
    position: absolute;
    z-index: 97;
    overflow-x: none;
    left: 0px !important;
    width: 100%;
  }
  .sidenav.active #open {
    position: absolute;
    z-index: 9;
    background-color: #23898e;
    cursor: pointer;
    width: 35px;
    height: 70px;
    border: none;
    top: 50%;
    right: 0px;
    transition: 1s;
  }
}

.hiddentext {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#tabbar.navbar-light .navbar-nav .nav-link {
    color: rgb(255, 255, 255);
}

@media only screen and (max-width: 900px) {
    .sc-fzozJi .text {
        display: none;
    }
    .sc-AxmLO {
        width: 50px !important;
    }
    .sc-fzoLsD {
        position: absolute !important;
        width: 100%;
        left: 0% !important;
    }
}

.disabled {
    cursor: default;
    -webkit-filter: blur(1px);
            filter: blur(1px);
}
.divchart {
    position: absolute;
    background-color: white;
    z-index: 96;
   /*  height: 76%; */
   height: 100%;
    width: 100%;
    padding: 20px;
    left: 100%;
    transition: 3s;
    overflow-y: scroll;
    padding-bottom: 3%;
    display: none;
    z-index: 95;
    padding-bottom: 10%;
}

.divchart.active {
    left: 0;
    display: block;
}

.divchart h1 {
    margin-top: 10px;
    font-family: 'Sarabun', sans-serif;
    text-align: center;
}
.pagination-section {
    margin: 3em 0;
    display: grid;
    place-items: center;
    height:20px;
    border-radius: 15%;
  }

  .pagination {
    list-style-type: none;
    display: flex;
    border: 1px solid black;
  }
  .pagination a {
    text-decoration: none;
    background: teal;
    color: #fff;
    padding: 0.5rem 0.5rem;
    height: 100%;
    border: 1px solid teal;
  }
  .pagination a:hover {
    background: rgb(204, 199, 199);
    color: #fff;
  }
  .pagination input {
   width: 50px;
   text-decoration: none;
   background: teal;
   color: #fff;
   padding: 0.5rem 0.5rem;
   border: 1px solid teal;
   border-radius: 0%;
   height: 100%;
  }
  .pagination p {
   text-decoration: none;
   background: teal;
   color: #fff;
   height: 100%;
   padding: 0.55rem 0.5rem;
   border: 1px solid teal;
  }


  .first a {
    display: inline-block;
    transition: all 300ms linear;
  }
  @-webkit-keyframes move {
    0% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(-100px);
    }
    40% {
      transform: translateX(100px);
    }
    60% {
      transform: translateX(-50px);
    }
    80% {
      transform: translateX(50px);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes move {
    0% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(-100px);
    }
    40% {
      transform: translateX(100px);
    }
    60% {
      transform: translateX(-50px);
    }
    80% {
      transform: translateX(50px);
    }
    100% {
      transform: translateX(0);
    }
  }

.divtable {
    position: absolute;
    background-color: white;
    z-index: 96;
   /*  height: 76%; */
   height: 100%;
    width: 100%;
    left: 100%;
    transition: 3s;
    display: none;
    z-index: 95;
}
.divtable.active {
    left: 0;
    display: block;
}

.divtable h1 {
    margin-top: 10px;
    font-family: 'Sarabun', sans-serif;
    text-align: center;
}
.table-fixed {
    background-color: #fbfbfb;
    width: 100%;
    height: 250px;
}
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}
.table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {

}
.table-fixed tbody td {

}
.table-fixed thead tr th {
    background-color:#159bd0;
    border-color:#0881b1;
      color:#fff;

}
.read_article{
	text-align:center;
}
.divdashboard {
  position: absolute;
  background-color: whitesmoke;
  z-index: 95;
 /*  height: 76%; */
 height: 100%;
  width: 100%;
  padding: 20px;
  left: 100%;
  transition: 3s;
  text-align: center;
  overflow-y: scroll;
  padding-bottom: 3%;
  display: none;
}

.divdashboard.active {
  left: 0%;
  display: block;
}
.containerdashboard {
  counter-reset: box;
  display: flex;
  flex-wrap: wrap;
  max-width: 1080px;
  margin: 0 auto;
  /* border: 1px dashed #aab2bd; */
}
.containerdashboard span {
    display: flex;
    flex-direction: column-reverse;
  text-align: center;
  vertical-align: middle;
  /* line-height: 90px; */
  font-size: 2rem;
  color: #fff;
}
.box {
  /* height: 100px; */
  padding: 20px;
  flex: 1 1 auto;
  position: relative;
  border: 2px solid #FFF;
}
.box::before {
  counter-increment: box;
  /* content: counter(box); */
  color: #fff;
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 8px);
  line-height: 18px;
  width: 20px;
  text-align: center;
}
.box:nth-child(4n + 1) {
  width: 300px;
  background-color: #48cfad;
}
.box:nth-child(4n + 2) {
  width: 300px;
  background-color: #4fc1e9;
}
.box:nth-child(4n + 3) {
  width: 300px;
  background-color: #ed5565;
}
.box:nth-child(4n + 4) {
  width: 300px;
  background-color: #ac92ec;
}

.modal-button {
  border: 0px;
  border-radius: 0.25em;
  color: rgb(255, 255, 255);
  font-size: 1.0625em;
  height: 50px;
  margin: 5px;
}
.testtest {
  color: #2bdf7f;
}

.modaltest{
    width: 25rem;
    height: auto;
}
  #modal-container {
    position: fixed;
    display: table;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    transform: scale(0);
    z-index: 999;
  }
  #modal-container.one {
    transform: scaleY(0.01) scaleX(0);
    -webkit-animation: unfoldIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: unfoldIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.one .modal-background .modaltest{
    transform: scale(0);
    -webkit-animation: zoomIn 0.5s 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: zoomIn 0.5s 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    z-index: 999;
  }
  #modal-container.one.out {
    transform: scale(1);
    -webkit-animation: unfoldOut 1s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: unfoldOut 1s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.one.out .modal-background .modaltest{
    -webkit-animation: zoomOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: zoomOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.two {
    transform: scale(1);
  }
  #modal-container.two .modal-background {
    background: rgba(0, 0, 0, 0);
    -webkit-animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.two .modal-background .modaltest{
    opacity: 0;
    -webkit-animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.two + .content {
    -webkit-animation: scaleBack 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: scaleBack 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.two.out {
    -webkit-animation: quickScaleDown 0s 0.5s linear forwards;
            animation: quickScaleDown 0s 0.5s linear forwards;
  }
  #modal-container.two.out .modal-background {
    -webkit-animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.two.out .modal-background .modaltest{
    -webkit-animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.two.out + .content {
    -webkit-animation: scaleForward 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: scaleForward 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.three {
    z-index: 0;
    transform: scale(1);
  }
  #modal-container.three .modal-background {
    background: rgba(0, 0, 0, 0.6);
  }
  #modal-container.three .modal-background .modaltest{
    -webkit-animation: moveUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: moveUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.three + .content {
    z-index: 1;
    -webkit-animation: slideUpLarge 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: slideUpLarge 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.three.out .modal-background .modaltest{
    -webkit-animation: moveDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: moveDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.three.out + .content {
    -webkit-animation: slideDownLarge 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: slideDownLarge 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.four {
    z-index: 0;
    transform: scale(1);
  }
  #modal-container.four .modal-background {
    background: rgba(0, 0, 0, 0.7);
  }
  #modal-container.four .modal-background .modaltest{
    -webkit-animation: blowUpModal 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: blowUpModal 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.four + .content {
    z-index: 1;
    -webkit-animation: blowUpContent 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: blowUpContent 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.four.out .modal-background .modaltest{
    -webkit-animation: blowUpModalTwo 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: blowUpModalTwo 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.four.out + .content {
    -webkit-animation: blowUpContentTwo 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: blowUpContentTwo 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.five {
    transform: scale(1);
  }
  #modal-container.five .modal-background {
    background: rgba(0, 0, 0, 0);
    -webkit-animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.five .modal-background .modaltest{
    transform: translateX(-1500px);
    -webkit-animation: roadRunnerIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: roadRunnerIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.five.out {
    -webkit-animation: quickScaleDown 0s 0.5s linear forwards;
            animation: quickScaleDown 0s 0.5s linear forwards;
  }
  #modal-container.five.out .modal-background {
    -webkit-animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.five.out .modal-background .modaltest{
    -webkit-animation: roadRunnerOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: roadRunnerOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.six {
    transform: scale(1);
  }
  #modal-container.six .modal-background {
    background: rgba(0, 0, 0, 0);
    -webkit-animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.six .modal-background .modaltest{
    background-color: transparent;
    -webkit-animation: modalFadeIn 0.5s 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: modalFadeIn 0.5s 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.six .modal-background .modaltesth2,
  #modal-container.six .modal-background .modaltestp {
    opacity: 0;
    position: relative;
    -webkit-animation: modalContentFadeIn 0.5s 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: modalContentFadeIn 0.5s 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.six .modal-background .modaltest.modal-svg rect {
    -webkit-animation: sketchIn 0.5s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: sketchIn 0.5s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.six.out {
    -webkit-animation: quickScaleDown 0s 0.5s linear forwards;
            animation: quickScaleDown 0s 0.5s linear forwards;
  }
  #modal-container.six.out .modal-background {
    -webkit-animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.six.out .modal-background .modaltest{
    -webkit-animation: modalFadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: modalFadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.six.out .modal-background .modaltesth2,
  #modal-container.six.out .modal-background .modaltestp {
    -webkit-animation: modalContentFadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: modalContentFadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.six.out .modal-background .modaltest.modal-svg rect {
    -webkit-animation: sketchOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: sketchOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.seven {
    transform: scale(1);
  }
  #modal-container.seven .modal-background {
    background: rgba(0, 0, 0, 0);
    -webkit-animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.seven .modal-background .modaltest{
    height: 75px;
    width: 75px;
    border-radius: 75px;
    overflow: hidden;
    -webkit-animation: bondJamesBond 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: bondJamesBond 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.seven .modal-background .modaltesth2,
  #modal-container.seven .modal-background .modaltestp {
    opacity: 0;
    position: relative;
    -webkit-animation: modalContentFadeIn 0.5s 1.4s linear forwards;
            animation: modalContentFadeIn 0.5s 1.4s linear forwards;
  }
  #modal-container.seven.out {
    -webkit-animation: slowFade 0.5s 1.5s linear forwards;
            animation: slowFade 0.5s 1.5s linear forwards;
  }
  #modal-container.seven.out .modal-background {
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-animation: fadeToRed 2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: fadeToRed 2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.seven.out .modal-background .modaltest{
    border-radius: 3px;
    height: 162px;
    width: 227px;
    -webkit-animation: killShot 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: killShot 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.seven.out .modal-background .modaltesth2,
  #modal-container.seven.out .modal-background .modaltestp {
    -webkit-animation: modalContentFadeOut 0.5s 0.5 cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: modalContentFadeOut 0.5s 0.5 cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container .modal-background {
    display: table-cell;
    background: rgba(0, 0, 0, 0.8);
    text-align: center;
    vertical-align: middle;
  }
  #modal-container .modal-background .modaltest{
    background: white;
    padding: 50px;
    display: inline-block;
    border-radius: 3px;
    font-weight: 300;
    position: relative;
  }
  #modal-container .modal-background .modaltesth2 {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 15px;
  }
  #modal-container .modal-background .modaltestp {
    font-size: 18px;
    line-height: 22px;
  }
  #modal-container .modal-background .modaltest.modal-svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 3px;
  }
  #modal-container .modal-background .modaltest.modal-svg rect {
    stroke: #fff;
    stroke-width: 2px;
    stroke-dasharray: 778;
    stroke-dashoffset: 778;
  }

  .content {
    min-height: 100%;
    height: 100%;
    background: white;
    position: relative;
    z-index: 0;
  }
  .content h1 {
    padding: 75px 0 30px 0;
    text-align: center;
    font-size: 30px;
    line-height: 30px;
  }
  .content .buttons {
    max-width: 800px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }
  .content .buttons .button {
    display: inline-block;
    text-align: center;
    padding: 10px 15px;
    margin: 10px;
    background: red;
    font-size: 18px;
    background-color: #efefef;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
  .content .buttons .button:hover {
    color: white;
    background: #009bd5;
  }

  @-webkit-keyframes unfoldIn {
    0% {
      transform: scaleY(0.005) scaleX(0);
    }
    50% {
      transform: scaleY(0.005) scaleX(1);
    }
    100% {
      transform: scaleY(1) scaleX(1);
    }
  }

  @keyframes unfoldIn {
    0% {
      transform: scaleY(0.005) scaleX(0);
    }
    50% {
      transform: scaleY(0.005) scaleX(1);
    }
    100% {
      transform: scaleY(1) scaleX(1);
    }
  }
  @-webkit-keyframes unfoldOut {
    0% {
      transform: scaleY(1) scaleX(1);
    }
    50% {
      transform: scaleY(0.005) scaleX(1);
    }
    100% {
      transform: scaleY(0.005) scaleX(0);
    }
  }
  @keyframes unfoldOut {
    0% {
      transform: scaleY(1) scaleX(1);
    }
    50% {
      transform: scaleY(0.005) scaleX(1);
    }
    100% {
      transform: scaleY(0.005) scaleX(0);
    }
  }
  @-webkit-keyframes zoomIn {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes zoomIn {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @-webkit-keyframes zoomOut {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes zoomOut {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      background: rgba(0, 0, 0, 0);
    }
    100% {
      background: rgba(0, 0, 0, 0.7);
    }
  }
  @keyframes fadeIn {
    0% {
      background: rgba(0, 0, 0, 0);
    }
    100% {
      background: rgba(0, 0, 0, 0.7);
    }
  }
  @-webkit-keyframes fadeOut {
    0% {
      background: rgba(0, 0, 0, 0.7);
    }
    100% {
      background: rgba(0, 0, 0, 0);
    }
  }
  @keyframes fadeOut {
    0% {
      background: rgba(0, 0, 0, 0.7);
    }
    100% {
      background: rgba(0, 0, 0, 0);
    }
  }
  @-webkit-keyframes scaleUp {
    0% {
      transform: scale(0.8) translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }
  @keyframes scaleUp {
    0% {
      transform: scale(0.8) translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes scaleDown {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
    100% {
      transform: scale(0.8) translateY(1000px);
      opacity: 0;
    }
  }
  @keyframes scaleDown {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
    100% {
      transform: scale(0.8) translateY(1000px);
      opacity: 0;
    }
  }
  @-webkit-keyframes scaleBack {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.85);
    }
  }
  @keyframes scaleBack {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.85);
    }
  }
  @-webkit-keyframes scaleForward {
    0% {
      transform: scale(0.85);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes scaleForward {
    0% {
      transform: scale(0.85);
    }
    100% {
      transform: scale(1);
    }
  }
  @-webkit-keyframes quickScaleDown {
    0% {
      transform: scale(1);
    }
    99.9% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes quickScaleDown {
    0% {
      transform: scale(1);
    }
    99.9% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @-webkit-keyframes slideUpLarge {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  @keyframes slideUpLarge {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  @-webkit-keyframes slideDownLarge {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  @keyframes slideDownLarge {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  @-webkit-keyframes moveUp {
    0% {
      transform: translateY(150px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes moveUp {
    0% {
      transform: translateY(150px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @-webkit-keyframes moveDown {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(150px);
    }
  }
  @keyframes moveDown {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(150px);
    }
  }
  @-webkit-keyframes blowUpContent {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    99.9% {
      transform: scale(2);
      opacity: 0;
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes blowUpContent {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    99.9% {
      transform: scale(2);
      opacity: 0;
    }
    100% {
      transform: scale(0);
    }
  }
  @-webkit-keyframes blowUpContentTwo {
    0% {
      transform: scale(2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes blowUpContentTwo {
    0% {
      transform: scale(2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes blowUpModal {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes blowUpModal {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @-webkit-keyframes blowUpModalTwo {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
  @keyframes blowUpModalTwo {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
  @-webkit-keyframes roadRunnerIn {
    0% {
      transform: translateX(-1500px) skewX(30deg) scaleX(1.3);
    }
    70% {
      transform: translateX(30px) skewX(0deg) scaleX(0.9);
    }
    100% {
      transform: translateX(0px) skewX(0deg) scaleX(1);
    }
  }
  @keyframes roadRunnerIn {
    0% {
      transform: translateX(-1500px) skewX(30deg) scaleX(1.3);
    }
    70% {
      transform: translateX(30px) skewX(0deg) scaleX(0.9);
    }
    100% {
      transform: translateX(0px) skewX(0deg) scaleX(1);
    }
  }
  @-webkit-keyframes roadRunnerOut {
    0% {
      transform: translateX(0px) skewX(0deg) scaleX(1);
    }
    30% {
      transform: translateX(-30px) skewX(-5deg) scaleX(0.9);
    }
    100% {
      transform: translateX(1500px) skewX(30deg) scaleX(1.3);
    }
  }
  @keyframes roadRunnerOut {
    0% {
      transform: translateX(0px) skewX(0deg) scaleX(1);
    }
    30% {
      transform: translateX(-30px) skewX(-5deg) scaleX(0.9);
    }
    100% {
      transform: translateX(1500px) skewX(30deg) scaleX(1.3);
    }
  }
  @-webkit-keyframes sketchIn {
    0% {
      stroke-dashoffset: 778;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes sketchIn {
    0% {
      stroke-dashoffset: 778;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @-webkit-keyframes sketchOut {
    0% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 778;
    }
  }
  @keyframes sketchOut {
    0% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 778;
    }
  }
  @-webkit-keyframes modalFadeIn {
    0% {
      background-color: transparent;
    }
    100% {
      background-color: white;
    }
  }
  @keyframes modalFadeIn {
    0% {
      background-color: transparent;
    }
    100% {
      background-color: white;
    }
  }
  @-webkit-keyframes modalFadeOut {
    0% {
      background-color: white;
    }
    100% {
      background-color: transparent;
    }
  }
  @keyframes modalFadeOut {
    0% {
      background-color: white;
    }
    100% {
      background-color: transparent;
    }
  }
  @-webkit-keyframes modalContentFadeIn {
    0% {
      opacity: 0;
      top: -20px;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }
  @keyframes modalContentFadeIn {
    0% {
      opacity: 0;
      top: -20px;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }
  @-webkit-keyframes modalContentFadeOut {
    0% {
      opacity: 1;
      top: 0px;
    }
    100% {
      opacity: 0;
      top: -20px;
    }
  }
  @keyframes modalContentFadeOut {
    0% {
      opacity: 1;
      top: 0px;
    }
    100% {
      opacity: 0;
      top: -20px;
    }
  }
  @-webkit-keyframes bondJamesBond {
    0% {
      transform: translateX(1000px);
    }
    80% {
      transform: translateX(0px);
      border-radius: 75px;
      height: 75px;
      width: 75px;
    }
    90% {
      border-radius: 3px;
      height: 182px;
      width: 247px;
    }
    100% {
      border-radius: 3px;
      height: 162px;
      width: 227px;
    }
  }
  @keyframes bondJamesBond {
    0% {
      transform: translateX(1000px);
    }
    80% {
      transform: translateX(0px);
      border-radius: 75px;
      height: 75px;
      width: 75px;
    }
    90% {
      border-radius: 3px;
      height: 182px;
      width: 247px;
    }
    100% {
      border-radius: 3px;
      height: 162px;
      width: 227px;
    }
  }
  @-webkit-keyframes killShot {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(300px) rotate(45deg);
      opacity: 0;
    }
  }
  @keyframes killShot {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(300px) rotate(45deg);
      opacity: 0;
    }
  }
  @-webkit-keyframes fadeToRed {
    0% {
      background-color: rgba(0, 0, 0, 0.6);
    }
    100% {
      background-color: rgba(255, 0, 0, 0.8);
    }
  }
  @keyframes fadeToRed {
    0% {
      background-color: rgba(0, 0, 0, 0.6);
    }
    100% {
      background-color: rgba(255, 0, 0, 0.8);
    }
  }
  @-webkit-keyframes slowFade {
    0% {
      opacity: 1;
    }
    99.9% {
      opacity: 0;
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes slowFade {
    0% {
      opacity: 1;
    }
    99.9% {
      opacity: 0;
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

.control {
    position: relative;
    z-index: 94;
}


/*------------------------------------------------selectfilter----------------------------------*/

.selectfilter {
    background-color: #DBEEF4;
    border: none;
    width: 100%;
}

.customoption {
    position: absolute;
    top: 45em;
    right: .5em;
    width: 50px;
    background-color: rgba(255, 255, 255, .4);
    border-radius: 4px;
    padding: 2px;
    text-align: center;
    z-index: 94;
}

.customoption img {
    padding: 2px;
}

.menuoption {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100px;
    right: .5em;
    width: 3em;
    background-color: rgba(255, 255, 255, .4);
    border-radius: 4px;
    padding: 2px;
    text-align: center;
    z-index: 94;
    cursor: pointer;
}

.menuoption img {
    padding: 2px 0 2px 0;
    width: 80%;
    height: 70%;
}

.menuoption .dropdown:hover {
    background-color: whitesmoke;
}

.menuoption a:hover {
    background-color: whitesmoke;
}

.menuoption input[type=radio] {
    margin-left: 5px;
}

.map:-webkit-full-screen {
    height: 0%;
    margin: 0;
}

.map:-ms-fullscreen {
    height: 100%;
}

.map:fullscreen {
    height: 100%;
}

.controlapi {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50px;
    right: .5em;
    width: 3em;
    background-color: rgba(255, 255, 255, .4);
    border-radius: 4px;
    padding: 2px;
    z-index: 9;
}

.controlapi div {
    position: relative;
    margin: 0 auto;
}

.controlapi div:nth-child(1) {
    right: .05em;
}

.controlapi div:nth-child(2) {
    right: .05em;
    margin-top: -1px;
}

.ol-control {
    display: block;
    /*แท็บซูมขยายพื้นฐาน ปิดชั่วคราว*/
    position: absolute;
    border-radius: 4px;
    padding: 2px;
    z-index: 9999;
}

.ol-zoom {
    top: 0!important;
    left: unset;
    right: .5em;
    font-size: 15px;
    text-align: center;
    border-radius: 2px 2px 0 0;
}

.fullscreen:-webkit-full-screen {
    height: 100%;
    margin: 0;
}

.fullscreen:-ms-fullscreen {
    height: 100%;
}

.fullscreen:fullscreen {
    height: 100%;
}

.fullscreen {
    position: absolute;
    top: 60px;
    left: unset;
    right: .5em;
    font-size: 15px;
    text-align: center;
    border-radius: 2px 2px 0 0;
}

.ol-control button {
    width: 2em !important;
    background-color: rgba(255, 255, 255, .4);
    color: black;
    right: 12px !important;
}

.ol-control button:hover {
    background-color: rgba(255, 255, 255, .4);
}

.dropdown-menu {
    background-color: #DBEEF4;
    padding-left: 10px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    padding: 5px 10px;
    position: absolute;
    background-color: #DBEEF4;
    right: 35px;
    /* top: 5px; */
    bottom: -5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    text-align: left;
    z-index: 90;
    border: 2px #23898e solid;
}

.dropdown-content label {
    padding-left: 3px;
}

.dropdown-content button {
    padding-left: 15px;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown .dropdown-content.active {
    display: block;
}

.t1 {
    left: 0px;
    top: 45px;
    width: 250px;
    max-height: 200px;
    overflow: auto;
}

.t3 {
    left: 0px;
    top: 45px;
    width: 300px;
}

.t5 {
    left: 0px;
    top: 45px;
    width: 400px;
}

.t6 {
    left: 0px;
    top: 45px;
    width: 350px;
}

.t7 {
    right: 50px;
    top: 45px;
    width: 350px;
}

.bootstrap-select .btn {
    background-color: #FFF;
    border-style: solid;
    border-left: none;
    border-top: none;
    border-bottom: none;
    border-right: none;
    color: gray;
    font-weight: 200;
    padding: 12px 12px;
    font-size: 20px;
    margin-bottom: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    overflow: hidden;
    text-align: center;
}


/*----------------------------------ป๊อปอัพโชวข้อมูล---------------------------------------*/

.ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 350px;
}

.ol-popup:after,
.ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}

.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

.ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
}

.ol-popup-closer:after {
    content: "✖";
}


/*----------------------------------------------------เครื่องมือเล่น เลเยอร์บนแผนที่-----------------------------*/

.ol-control.ol-timeline {
    position: fixed;
    bottom: 40px;
    left: 0;
    right: 0;
    transition: .3s;
}

.ol-attribution {
    bottom: 4.5em;
}

.ol-timeline {
    font-size: 2em;
}

.ol-timeline .ol-scroll {
    height: 2em;
}

.ol-timeline .ol-features {
    text-align: center;
}

#select {
    font-size: .85em;
    margin: 1em 0;
}

#select p {
    margin: 0;
}

#select .copy {
    font-size: .9em;
    color: #396;
}

button.go {
    background-color: #3B71A7;
    height: 100%;
    width: 5rem;
    border: 2px solid black;
}

button.go:hover {
    background-color: rgb(25, 112, 199);
}

button.go:before {
    content: '';
    position: absolute;
    width: 0;
    height: .8em;
    background: transparent;
    border: 0.4em solid transparent;
    border-right: 0;
    border-left: .6em solid #fff;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
}

.running button.go:before {
    background: transparent;
    width: .2em;
    border: 0;
    box-shadow: .2em 0, -.2em 0;
}


/*-----------------โชวข้อความแนะนำผู้ใช้งาน--------------------*/

.showtext {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 999;
    opacity: 0;
    color: white;
    font-size: 20px;
    transform: translateY(25px);
    transition: 0.4s all linear;
}

.textshowpopup {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 999;
    opacity: 0;
    color: white;
    font-size: 20px;
    transition: 0.4s all linear;
}


/*-------------------------------------------------------*/

.ol-swipe {
    background-color: #dee2e6;
}

.btn-group button {
    border: none;
    background: none;
}

.divinfo {
    position: relative;
    z-index: 94;
}

.info {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 460px;
    right: .5em;
    width: 3em;
    background-color: rgb(225, 251, 255, 0.5);
    border-radius: 4px;
    padding: 2px;
    text-align: center;
    z-index: 94;
    cursor: pointer;
}

#map {
    z-index: 0;
}

@media only screen and (max-width: 900px) {
    .info {
        top: 470px !important;
    }
    .ol-control button {
        width: 35px !important;
        background-color: rgba(255, 255, 255, .4);
        color: black;
        right: 12px !important;
    }
    .ol-control.fullscreen {
        width: 2.6em !important;
        background-color: rgba(255, 255, 255, .4);
        color: black;
        top: 70px;
        height: 10px;
        right: 8px !important;
    }
    .card {
        position: absolute !important;
        width: 80% !important;
        /* left: 50% !important;
right: 50% !important; */
        margin: 0% auto !important;
        bottom: 0% !important;
    }
}


/*
@media only screen and (max-width: 600px) {
.info{
  top: 400px !important;
}
}
@media only screen and (max-width: 500px) {
  .info{
    top: 400px !important;
  }
} */


/* @media only screen and (max-width: 900px) {
  .menuoption {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100px;
    right: .5em;
    width: 3em;
    background-color: rgba(255, 255, 255, .4);
    border-radius: 4px;
    padding: 2px;
    text-align: center;
    z-index: 94;
    cursor: pointer;
}

.menuoption img {
    padding-top: 5px;
    width: 70%;
    height: 20%;
}
} */
* {
    box-sizing: border-box;
  }

  body {
    padding: 0;
    margin: 0;
  }

  #notfound {
    position: relative;
    height: 100vh;
    background: #030005;
  }

  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .notfound {
    max-width: 767px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
  }

  .notfound .notfound-404 {
    position: relative;
    height: 180px;
    margin-bottom: 20px;
    z-index: -1;
  }

  .notfound .notfound-404 h1 {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50% , -50%);
    font-size: 224px;
    font-weight: 900;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: -12px;
    color: #030005;
    text-transform: uppercase;
    text-shadow: -1px -1px 0px #8400ff, 1px 1px 0px #ff005a;
    letter-spacing: -20px;
  }


  .notfound .notfound-404 h2 {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    left: 0;
    right: 0;
    top: 110px;
    font-size: 42px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0px 2px 0px #8400ff;
    letter-spacing: 13px;
    margin: 0;
  }
  .notfound .notfound-404 h3 {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    left: 0;
    right: 0;
    top: 150px;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0px 2px 0px #8400ff;
    letter-spacing: 13px;
    margin: 0;
  }

  .notfound a {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    text-transform: uppercase;
    color: #ff005a;
    text-decoration: none;
    border: 2px solid;
    background: transparent;
    padding: 10px 40px;
    font-size: 14px;
    margin-top: 20px;
    font-weight: 700;
    transition: 0.2s all;
  }

  .notfound a:hover {
    color: #8400ff;
  }

  @media only screen and (max-width: 767px) {
      .notfound .notfound-404 h2 {
          font-size: 24px;
      }
  }

  @media only screen and (max-width: 480px) {
    .notfound .notfound-404 h1 {
        font-size: 182px;
    }
  }
