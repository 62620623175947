.control {
    position: relative;
    z-index: 94;
}


/*------------------------------------------------selectfilter----------------------------------*/

.selectfilter {
    background-color: #DBEEF4;
    border: none;
    width: 100%;
}

.customoption {
    position: absolute;
    top: 45em;
    right: .5em;
    width: 50px;
    background-color: rgba(255, 255, 255, .4);
    border-radius: 4px;
    padding: 2px;
    text-align: center;
    z-index: 94;
}

.customoption img {
    padding: 2px;
}

.menuoption {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100px;
    right: .5em;
    width: 3em;
    background-color: rgba(255, 255, 255, .4);
    border-radius: 4px;
    padding: 2px;
    text-align: center;
    z-index: 94;
    cursor: pointer;
}

.menuoption img {
    padding: 2px 0 2px 0;
    width: 80%;
    height: 70%;
}

.menuoption .dropdown:hover {
    background-color: whitesmoke;
}

.menuoption a:hover {
    background-color: whitesmoke;
}

.menuoption input[type=radio] {
    margin-left: 5px;
}

.map:-webkit-full-screen {
    height: 0%;
    margin: 0;
}

.map:-ms-fullscreen {
    height: 100%;
}

.map:fullscreen {
    height: 100%;
}

.controlapi {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50px;
    right: .5em;
    width: 3em;
    background-color: rgba(255, 255, 255, .4);
    border-radius: 4px;
    padding: 2px;
    z-index: 9;
}

.controlapi div {
    position: relative;
    margin: 0 auto;
}

.controlapi div:nth-child(1) {
    right: .05em;
}

.controlapi div:nth-child(2) {
    right: .05em;
    margin-top: -1px;
}

.ol-control {
    display: block;
    /*แท็บซูมขยายพื้นฐาน ปิดชั่วคราว*/
    position: absolute;
    border-radius: 4px;
    padding: 2px;
    z-index: 9999;
}

.ol-zoom {
    top: 0!important;
    left: unset;
    right: .5em;
    font-size: 15px;
    text-align: center;
    border-radius: 2px 2px 0 0;
}

.fullscreen:-webkit-full-screen {
    height: 100%;
    margin: 0;
}

.fullscreen:-ms-fullscreen {
    height: 100%;
}

.fullscreen:fullscreen {
    height: 100%;
}

.fullscreen {
    position: absolute;
    top: 60px;
    left: unset;
    right: .5em;
    font-size: 15px;
    text-align: center;
    border-radius: 2px 2px 0 0;
}

.ol-control button {
    width: 2em !important;
    background-color: rgba(255, 255, 255, .4);
    color: black;
    right: 12px !important;
}

.ol-control button:hover {
    background-color: rgba(255, 255, 255, .4);
}

.dropdown-menu {
    background-color: #DBEEF4;
    padding-left: 10px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    padding: 5px 10px;
    position: absolute;
    background-color: #DBEEF4;
    right: 35px;
    /* top: 5px; */
    bottom: -5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    text-align: left;
    z-index: 90;
    border: 2px #23898e solid;
}

.dropdown-content label {
    padding-left: 3px;
}

.dropdown-content button {
    padding-left: 15px;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown .dropdown-content.active {
    display: block;
}

.t1 {
    left: 0px;
    top: 45px;
    width: 250px;
    max-height: 200px;
    overflow: auto;
}

.t3 {
    left: 0px;
    top: 45px;
    width: 300px;
}

.t5 {
    left: 0px;
    top: 45px;
    width: 400px;
}

.t6 {
    left: 0px;
    top: 45px;
    width: 350px;
}

.t7 {
    right: 50px;
    top: 45px;
    width: 350px;
}

.bootstrap-select .btn {
    background-color: #FFF;
    border-style: solid;
    border-left: none;
    border-top: none;
    border-bottom: none;
    border-right: none;
    color: gray;
    font-weight: 200;
    padding: 12px 12px;
    font-size: 20px;
    margin-bottom: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    overflow: hidden;
    text-align: center;
}


/*----------------------------------ป๊อปอัพโชวข้อมูล---------------------------------------*/

.ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 350px;
}

.ol-popup:after,
.ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}

.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

.ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
}

.ol-popup-closer:after {
    content: "✖";
}


/*----------------------------------------------------เครื่องมือเล่น เลเยอร์บนแผนที่-----------------------------*/

.ol-control.ol-timeline {
    position: fixed;
    bottom: 40px;
    left: 0;
    right: 0;
    -webkit-transition: .3s;
    transition: .3s;
}

.ol-attribution {
    bottom: 4.5em;
}

.ol-timeline {
    font-size: 2em;
}

.ol-timeline .ol-scroll {
    height: 2em;
}

.ol-timeline .ol-features {
    text-align: center;
}

#select {
    font-size: .85em;
    margin: 1em 0;
}

#select p {
    margin: 0;
}

#select .copy {
    font-size: .9em;
    color: #396;
}

button.go {
    background-color: #3B71A7;
    height: 100%;
    width: 5rem;
    border: 2px solid black;
}

button.go:hover {
    background-color: rgb(25, 112, 199);
}

button.go:before {
    content: '';
    position: absolute;
    width: 0;
    height: .8em;
    background: transparent;
    border: 0.4em solid transparent;
    border-right: 0;
    border-left: .6em solid #fff;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
}

.running button.go:before {
    background: transparent;
    width: .2em;
    border: 0;
    box-shadow: .2em 0, -.2em 0;
}


/*-----------------โชวข้อความแนะนำผู้ใช้งาน--------------------*/

.showtext {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 999;
    opacity: 0;
    color: white;
    font-size: 20px;
    transform: translateY(25px);
    transition: 0.4s all linear;
}

.textshowpopup {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 999;
    opacity: 0;
    color: white;
    font-size: 20px;
    transition: 0.4s all linear;
}


/*-------------------------------------------------------*/

.ol-swipe {
    background-color: #dee2e6;
}

.btn-group button {
    border: none;
    background: none;
}

.divinfo {
    position: relative;
    z-index: 94;
}

.info {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 460px;
    right: .5em;
    width: 3em;
    background-color: rgb(225, 251, 255, 0.5);
    border-radius: 4px;
    padding: 2px;
    text-align: center;
    z-index: 94;
    cursor: pointer;
}

#map {
    z-index: 0;
}

@media only screen and (max-width: 900px) {
    .info {
        top: 470px !important;
    }
    .ol-control button {
        width: 35px !important;
        background-color: rgba(255, 255, 255, .4);
        color: black;
        right: 12px !important;
    }
    .ol-control.fullscreen {
        width: 2.6em !important;
        background-color: rgba(255, 255, 255, .4);
        color: black;
        top: 70px;
        height: 10px;
        right: 8px !important;
    }
    .card {
        position: absolute !important;
        width: 80% !important;
        /* left: 50% !important;
right: 50% !important; */
        margin: 0% auto !important;
        bottom: 0% !important;
    }
}


/*
@media only screen and (max-width: 600px) {
.info{
  top: 400px !important;
}
}
@media only screen and (max-width: 500px) {
  .info{
    top: 400px !important;
  }
} */


/* @media only screen and (max-width: 900px) {
  .menuoption {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100px;
    right: .5em;
    width: 3em;
    background-color: rgba(255, 255, 255, .4);
    border-radius: 4px;
    padding: 2px;
    text-align: center;
    z-index: 94;
    cursor: pointer;
}

.menuoption img {
    padding-top: 5px;
    width: 70%;
    height: 20%;
}
} */