#tabbar.navbar-light .navbar-nav .nav-link {
    color: rgb(255, 255, 255);
}

@media only screen and (max-width: 900px) {
    .sc-fzozJi .text {
        display: none;
    }
    .sc-AxmLO {
        width: 50px !important;
    }
    .sc-fzoLsD {
        position: absolute !important;
        width: 100%;
        left: 0% !important;
    }
}

.disabled {
    cursor: default;
    filter: blur(1px);
}