.sidenav {
  position: absolute;
  z-index: 97;
  height: 100%;
  width: 25%;
  left: -25%;
  background-color: #fff;
  overflow-x: none;
  transition: 0.5s all linear;
  border-top: 2px solid #23898e;
  border-bottom: 2px solid #23898e;
  border-right: 2px solid #23898e;

}
/*-----------------ลบปุ่มออกเมื่อเมนูปิดอยู่ */
.sidenav .sc-AxhUy{
 visibility: hidden;
 transition: 1s all linear ;
 z-index: 90;
}
.sidenav.active .sc-AxhUy{
  visibility: visible;
}
/*----------------------------------*/

.sidenavfullscreen {
  height: 100% !important;
}

.sidenav #open {
  visibility: visible;
}

.sidenav.active {
  position: absolute;
  z-index: 97;
  overflow-x: none;
  left: 0px !important;
}


.sidenav:hover i {
  margin-right: 10px;
  transform: rotate(180deg);
}

.tab {
  height: auto;
  width: 100%;
}

.tab .hide {
  display: none;
}

.tab button {
  display: inline;
}

.headnav button:hover {
  color: #d4cfcf;
}

.main {
  margin-left: 160px;
  /* Same as the width of the sidenav */
  font-size: 28px;
  /* Increased text to enable scrolling */
  padding: 0px 10px;
}

.hide {
  display: none;
}

.sidenav .sidecontent ,.sidecontentswipe{
  position: relative;
  width: 100%;
  height: 90%;

  padding-bottom: 30px;
  overflow-y: auto;


}

#dragbar {
  background-color: #67a6fc;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  cursor: col-resize;
  z-index: 99;
}

#dragbar:hover {
  width: 5px;
  cursor: col-resize;
  z-index: 99;
}
.description {
  position: relative;
}
@media only screen and (max-width: 900px) {
  .sidenav.active {
    position: absolute;
    z-index: 97;
    overflow-x: none;
    left: 0px !important;
    width: 100%;
  }
  .sidenav.active #open {
    position: absolute;
    z-index: 9;
    background-color: #23898e;
    cursor: pointer;
    width: 35px;
    height: 70px;
    border: none;
    top: 50%;
    right: 0px;
    -webkit-transition: 1s;
    transition: 1s;
  }
}

.hiddentext {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
