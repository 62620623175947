html, body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }
/* .map {
    position: relative;
    height: 70vh;
    width: 100%;
    margin: 0;
    padding: 0;
} */
  .fullscreen:-webkit-full-screen {
    height: 100%;
    margin: 0;
  }
  .fullscreen:-ms-fullscreen {
    height: 100%;
  }

  .fullscreen:fullscreen {
    height: 100%;
  }


#panel {
    background-color: #A6BDCE;
    top: 0;
}


.filter {
    position: absolute;
    z-index: 998;
    margin: 4% 1%;
    border-style: outset;
    border-width: 5px;
    border-color: #21d4c3;
}

.description {
    position: absolute;
    z-index: 98;
    right: 0.5rem;
    top: 95%;
}

.showdescription {
    position: absolute;
    z-index: 99;
    right: 5rem;
    bottom: 2vh;
    display: none;
    max-height: 100vh;
    max-width: 450px;
    border: 2px solid #7EA6B9;
    background-color: #7ea6b947;
}

.showdescription img {
    width: 100%;
    height: 15vh;
}

#toolbal button {
    height: 50px;
    overflow: hidden;
    width: 145px;
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0px 0px;
    transition: 0.3s;
}
/*
#toolbal .dropdown-content button {
    background-color: #8dacbbb8;
}

#toolbal button:hover {
    background-color: #7EA6B9;
}

#toolbal button.active {
    background-color: #7EA6B9;
}
 */
#menu-top{
    position: sticky;
    left: 50%;
}
#menu-top button {
    position: relative;
    background-color: #558ED5;
    border: none;
    height: 40px;
    margin-top: -6px;

    /* margin: 1px; */
}

#menu-top img {
    position: relative;
    border: none;
    top: 0px;
}

#menu-top button:hover {
    background-color: #f9fff9;
}

#menu-top .active {
    background-color: #f9fff9;
    display: inline-table;
}

.maptabnew {
    cursor: pointer;
    margin-left: 10px;
}

.maptabnew:hover {
    background-color: #6495ed;
}


.bootstrap-select .btn {
    background-color: #FFF;
    border-style: solid;
    border-left: none;
    border-top: none;
    border-bottom: none;
    border-right: none;
    color: gray;
    font-weight: 200;
    padding: 12px 12px;
    font-size: 20px;
    margin-bottom: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    overflow: hidden;
    text-align: center;
}


/* ---------------------------------------------------
  SIDEBAR STYLE
----------------------------------------------------- */

.sidebar-right {
    width: 100%;
    position: absolute;
    top: 0;
    right: -100%;
    height: 100%;
    z-index: 99;
    background: #FFF;
    color: #fff;
    transition: all 0.3s;
    overflow-y: scroll;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

.sidebar-right.active {
    right: 0;
}

#dismiss {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #7386D5;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#dismiss:hover {
    background: #fff;
    color: #7386D5;
}


/*------------------------------------เมนูslide-chart---------------------------------------*/

.menuchart {
    background-color: #21d4c3;
}

.menuchart:active {
    background-color: red;
}


/*---------------------------------------------ฟังชั่นเล่นแสดงmap range input play------------------------------------*/

.btnplayslidemap:hover {
    text-shadow: -2px 4px 0 #3cdaf3, -2px -4px 0 #3cdaf3, 4px 0px 0 #3cdaf3;
    cursor: pointer;
}

.range input {
    width: 100%;
    position: absolute;
    top: 50%;
    margin-left: 15px;
    height: 0;
    -webkit-appearance: none;
}

.range input::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    margin: -8px 0 0;
    border-radius: 50%;
    background: #37adbf;
    cursor: pointer;
    border: 0 !important;
}

.range input::-moz-range-thumb {
    width: 18px;
    height: 18px;
    margin: -8px 0 0;
    border-radius: 50%;
    background: #37adbf;
    cursor: pointer;
    border: 0 !important;
}

.range input::-ms-thumb {
    width: 18px;
    height: 18px;
    margin: -8px 0 0;
    border-radius: 50%;
    background: #37adbf;
    cursor: pointer;
    border: 0 !important;
}

.range input::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #b2b2b2;
}

.range input::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #b2b2b2;
}

.range input::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #b2b2b2;
}

.range input:focus {
    background: none;
    outline: none;
}

.range input::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}


/*---------------------------------------------------------------------------*/

.fs {
    font-size: 13px;
}


/*#navbar {
    background-image: url(http://gis.nso.go.th/portal/wp-content/uploads/2020/02/header-bgNew.png);
    background-size: 100%;
}*/


#navbar a {
   /*  color: white; */
}

.nav-link {
    transition: 0.3s linear;
}

.nav-link:hover {
    border-bottom: 2px solid #18A2B8;
    padding: 6px 0;
}

.nav-link.active {
    border-bottom: 2px solid #18A2B8;
    padding: 6px 0;
}


li {
    list-style-type: none;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #ffffff;
    content: "/";
}

.grecaptcha-badge {
    visibility: hidden;
    z-index: 2000;
}
.showwmsapi{
    overflow: auto;
    border: 1px solid black;
    height: 100px;

}
.showwmsapi::-webkit-scrollbar {
    width: 5px;
  }
.dropdown-menushow{
    display: flex;
}

@media only screen and (max-width: 800px) {
    #menu-top button {
        margin-top: 10px;
    }

}
@media only screen and (max-width: 600px) {
    .navlogo{
        width: 140px!important;
    }
    .footer{
        display: none;
    }
}