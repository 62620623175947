.divtable {
    position: absolute;
    background-color: white;
    z-index: 96;
   /*  height: 76%; */
   height: 100%;
    width: 100%;
    left: 100%;
    transition: 3s;
    display: none;
    z-index: 95;
}
.divtable.active {
    left: 0;
    display: block;
}

.divtable h1 {
    margin-top: 10px;
    font-family: 'Sarabun', sans-serif;
    text-align: center;
}
.table-fixed {
    background-color: #fbfbfb;
    width: 100%;
    height: 250px;
}
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}
.table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {

}
.table-fixed tbody td {

}
.table-fixed thead tr th {
    background-color:#159bd0;
    border-color:#0881b1;
      color:#fff;

}
.read_article{
	text-align:center;
}