.divdashboard {
  position: absolute;
  background-color: whitesmoke;
  z-index: 95;
 /*  height: 76%; */
 height: 100%;
  width: 100%;
  padding: 20px;
  left: 100%;
  transition: 3s;
  text-align: center;
  overflow-y: scroll;
  padding-bottom: 3%;
  display: none;
}

.divdashboard.active {
  left: 0%;
  display: block;
}
.containerdashboard {
  counter-reset: box;
  display: flex;
  flex-wrap: wrap;
  max-width: 1080px;
  margin: 0 auto;
  /* border: 1px dashed #aab2bd; */
}
.containerdashboard span {
    display: flex;
    flex-direction: column-reverse;
  text-align: center;
  vertical-align: middle;
  /* line-height: 90px; */
  font-size: 2rem;
  color: #fff;
}
.box {
  /* height: 100px; */
  padding: 20px;
  flex: 1 1 auto;
  position: relative;
  border: 2px solid #FFF;
}
.box::before {
  counter-increment: box;
  /* content: counter(box); */
  color: #fff;
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 8px);
  line-height: 18px;
  width: 20px;
  text-align: center;
}
.box:nth-child(4n + 1) {
  width: 300px;
  background-color: #48cfad;
}
.box:nth-child(4n + 2) {
  width: 300px;
  background-color: #4fc1e9;
}
.box:nth-child(4n + 3) {
  width: 300px;
  background-color: #ed5565;
}
.box:nth-child(4n + 4) {
  width: 300px;
  background-color: #ac92ec;
}
